$cu_spaces: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 30, 31, 32, 33, 34, 35, 36, 37, 38,
  39, 40;

$cu_sides: "top", "left", "bottom", "right";
@each $space in $cu_spaces {
  .m_#{$space} {
    margin: #{calc($space / 4)}rem;
  }

  .mx_#{$space} {
    margin-left: #{calc($space / 4)}rem;
    margin-right: #{calc($space / 4)}rem;
  }

  .my_#{$space} {
    margin-top: #{calc($space / 4)}rem;
    margin-bottom: #{calc($space / 4)}rem;
  }

  .px_#{$space} {
    padding-left: #{calc($space / 4)}rem;
    padding-right: #{calc($space / 4)}rem;
  }

  .py_#{$space} {
    padding-top: #{calc($space / 4)}rem;
    padding-bottom: #{calc($space / 4)}rem;
  }

  .p_#{$space} {
    padding: #{calc($space / 4)}rem;
  }

  .ms_#{$space} {
    margin-left: #{calc($space / 4)}rem;
  }

  .ps_#{$space} {
    padding-left: #{calc($space / 4)}rem;
  }
  .me_#{$space} {
    margin-right: #{calc($space / 4)}rem;
  }

  .pe_#{$space} {
    padding-right: #{calc($space / 4)}rem;
  }
  .mt_#{$space} {
    margin-top: #{calc($space / 4)}rem;
  }

  .pt_#{$space} {
    padding-top: #{calc($space / 4)}rem;
  }
  .mb_#{$space} {
    margin-bottom: #{calc($space / 4)}rem;
  }

  .pb_#{$space} {
    padding-bottom: #{calc($space / 4)}rem;
  }
}
@function pxToRem($pxSize) {
  @return calc($pxSize / 16) + rem;
}
$font-size-list: 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24
  25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 41 42 43 44 45 46 47 48 49 50
  51 52 53 54 55 56 57 58 59 60 61 62 63 64 65 66 67 68 69 70 71 72 73 74 75 76
  77 78 79 80 81 82 83 84 85 86 87 88 89 90 91 92 93 94 95 96 97 98 99 100;

@each $value in $font-size-list {
  .fs#{$value} {
    font-size: pxToRem($value);
  }
  @media (min-width: 576px) {
    .fssm#{$value} {
      font-size: pxToRem($value);
    }
  }
}
@each $value in $font-size-list {
  .fsd#{$value} {
    font-size: #{calc($value / 10)}vw;
  }
  @media (min-width: 576px) {
    .fsd_sm#{$value} {
      font-size: #{calc($value / 10)}vw;
    }
  }
}
$percentage-size-list: 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22
  23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 41 42 43 44 45 46 47 48
  49 50 51 52 53 54 55 56 57 58 59 60 61 62 63 64 65 66 67 68 69 70 71 72 73 74
  75 76 77 78 79 80 81 82 83 84 85 86 87 88 89 90 91 92 93 94 95 96 97 98 99 100;

@each $value in $percentage-size-list {
  .w-#{$value} {
    width: #{$value}+ "%";
  }
}
