#blog {
}
#blog section.row {
  padding-left: 2vw;
  padding-right: 2vw;
}
#blog section.row .first {
  padding-left: 4vw;
  padding-right: 4vw;
}
#blog section.row .second {
  margin-top: 0;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 992px) {
  #blog section.row {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  #blog section.row .first {
    padding-left: 9vw;
  }
  #blog section.row .second {
    margin-top: 0rem;
    padding-right: 8vw;
    padding-left: 0vw;
  }
}
#blog .posts {
}
#blog .posts .post {
  display: flex;
  margin-bottom: 1rem;
}
#blog .posts .post div {
  padding-left: 0;
  padding-right: 0;
}
#blog .posts .post .left {
  padding-top: 0.25rem;
  width: 16%;
}
#blog .posts .post .right {
  width: 84%;
}
#blog .posts .post .date {
  font-size: 16px;
}
#blog .posts .post .duration {
  font-size: 16px;
  padding-top: 0.1rem;
}
#blog .posts .post .title {
  font-weight: 700;
  font-size: 20px;
}
#blog .posts .post .sub-title {
  font-weight: 400;
  font-size: 16px;
}
#blog .posts .post .read-more {
  font-size: 16px;
  font-weight: 700;
  color: var(--bs-main);
}
