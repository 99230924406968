:root {
  --ratio-square: 1;
  --ratio-landscape: 4/3;
  --ratio-portrait: 3/4;
  --ratio-widescreen: 16/9;
  --ratio-ultrawide: 18/5;
  --ratio-golden: 1.618/1;

  --font-dm-sans: "DM Sans", sans-serif;
  --font-inter: "Inter", sans-serif;

  --font-weight-1: 100;
  --font-weight-2: 200;
  --font-weight-3: 300;
  --font-weight-4: 400;
  --font-weight-5: 500;
  --font-weight-6: 600;
  --font-weight-7: 700;
  --font-weight-8: 800;
  --font-weight-9: 900;
  --font-lineheight-00: 0.95;
  --font-lineheight-0: 1.1;
  --font-lineheight-1: 1.25;
  --font-lineheight-2: 1.375;
  --font-lineheight-3: 1.5;
  --font-lineheight-4: 1.75;
  --font-lineheight-5: 2;
  --font-letterspacing-0: -0.05em;
  --font-letterspacing-1: 0.025em;
  --font-letterspacing-2: 0.05em;
  --font-letterspacing-3: 0.075em;
  --font-letterspacing-4: 0.15em;
  --font-letterspacing-5: 0.5em;
  --font-letterspacing-6: 0.75em;
  --font-letterspacing-7: 1em;
  --font-size-00: 0.5rem;
  --font-size-0: 0.75rem;
  --font-size-1: 1rem;
  --font-size-2: 1.1rem;
  --font-size-3: 1.25rem;
  --font-size-4: 1.5rem;
  --font-size-5: 2rem;
  --font-size-6: 2.5rem;
  --font-size-7: 3rem;
  --font-size-8: 3.5rem;

  --font-size-fluid-0: clamp(0.75rem, 2vw, 1rem);
  --font-size-fluid-1: clamp(1rem, 4vw, 1.5rem);
  --font-size-fluid-2: clamp(1.5rem, 6vw, 2.5rem);
  --font-size-fluid-3: clamp(2rem, 9vw, 3.5rem);

  --shadow-color: 220 3% 15%;
  --shadow-strength: 1%;
  --shadow-1: 0 1px 2px -1px hsl(var(--shadow-color) /
        calc(var(--shadow-strength) + 9%));
  --shadow-2: 0 3px 5px -2px hsl(var(--shadow-color) /
          calc(var(--shadow-strength) + 3%)),
    0 7px 14px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 5%));
  --shadow-3: 0 -1px 3px 0 hsl(var(--shadow-color) /
          calc(var(--shadow-strength) + 2%)),
    0 1px 2px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 2%)),
    0 2px 5px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 4%)),
    0 4px 12px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 5%)),
    0 12px 15px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 7%));
  --shadow-4: 0 -2px 5px 0 hsl(var(--shadow-color) /
          calc(var(--shadow-strength) + 2%)),
    0 1px 1px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 3%)),
    0 2px 2px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 3%)),
    0 5px 5px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 4%)),
    0 9px 9px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 5%)),
    0 16px 16px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 6%));
  --shadow-5: 0 -1px 2px 0 hsl(var(--shadow-color) /
          calc(var(--shadow-strength) + 2%)),
    0 2px 1px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 3%)),
    0 5px 5px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 3%)),
    0 10px 10px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 4%)),
    0 20px 20px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 5%)),
    0 40px 40px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 7%));
  --shadow-6: 0 -1px 2px 0 hsl(var(--shadow-color) /
          calc(var(--shadow-strength) + 2%)),
    0 3px 2px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 3%)),
    0 7px 5px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 3%)),
    0 12px 10px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 4%)),
    0 22px 18px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 5%)),
    0 41px 33px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 6%)),
    0 100px 80px -2px hsl(var(--shadow-color) /
          calc(var(--shadow-strength) + 7%));
  --inner-shadow-0: inset 0 0 0 1px
    hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%));
  --inner-shadow-1: inset 0 1px 2px 0
    hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%));
  --inner-shadow-2: inset 0 1px 4px 0
    hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%));
  --inner-shadow-3: inset 0 2px 8px 0
    hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%));
  --inner-shadow-4: inset 0 2px 14px 0
    hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%));

  --size-000: -0.5rem;
  --size-00: -0.25rem;
  --size-1: 0.25rem;
  --size-2: 0.5rem;
  --size-3: 1rem;
  --size-4: 1.25rem;
  --size-5: 1.5rem;
  --size-6: 1.75rem;
  --size-7: 2rem;
  --size-8: 3rem;
  --size-9: 4rem;
  --size-10: 5rem;
  --size-11: 7.5rem;
  --size-12: 10rem;
  --size-13: 15rem;
  --size-14: 20rem;
  --size-15: 30rem;
  --size-fluid-1: clamp(0.5rem, 1vw, 1rem);
  --size-fluid-2: clamp(1rem, 2vw, 1.5rem);
  --size-fluid-3: clamp(1.5rem, 3vw, 2rem);
  --size-fluid-4: clamp(2rem, 4vw, 3rem);
  --size-fluid-5: clamp(4rem, 5vw, 5rem);
  --size-fluid-6: clamp(5rem, 7vw, 7.5rem);
  --size-fluid-7: clamp(7.5rem, 10vw, 10rem);
  --size-fluid-8: clamp(10rem, 20vw, 15rem);
  --size-fluid-9: clamp(15rem, 30vw, 20rem);
  --size-fluid-10: clamp(20rem, 40vw, 30rem);
  --size-content-1: 20ch;
  --size-content-2: 45ch;
  --size-content-3: 60ch;
  --size-header-1: 20ch;
  --size-header-2: 25ch;
  --size-header-3: 35ch;
  --size-xxs: 240px;
  --size-xs: 360px;
  --size-sm: 480px;
  --size-md: 768px;
  --size-lg: 1024px;
  --size-xl: 1440px;
  --size-xxl: 1920px;
  --bs-main: #5193c6;
  --bs-steel-blue: #7992dd;
  --bs-jade-green: #2eb459;
  --bs-dust-storm: #e3cdbf;
  --bs-robin-egg: #5193c6;
  --bs-greenish-beige: #cccb78;
  --bs-light-back: #f2f7fb;
  --bs-coral: #e2a7a6;
}

.text-main {
  color: var(--bs-main) !important;
}
.bg-main {
  background-color: var(--bs-main);
}
.border-main {
  border-color: var(--bs-main);
  --bs-border-color: var(--bs-main);
}
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300;8..144,500;8..144,700&display=swap");

.roboto {
  font-family: "Roboto Flex", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
.dm-sans {
  font-family: "DM Sans", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap");
.inter {
  font-family: "Inter", sans-serif;
}
