#ecycle section.hero {
  background-color: var(--bs-jade-green);
  width: 100vw;
  height: 119vw;
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 576px) {
  #ecycle section.hero {
    height: 66.5vw;
  }
}
#ecycle .ecycle-title {
  position: absolute;
  color: white;
  font-size: 8.3vw;
  top: 89vw;
  left: 10vw;
}
#ecycle .ecycle-sub-title {
  position: absolute;
  color: white;
  font-size: 5vw;
  top: 100vw;
  left: 9.5vw;
  width: 77vw;
}
@media (min-width: 576px) {
  #ecycle .ecycle-title {
    display: none;
  }
  #ecycle .ecycle-sub-title {
    display: none;
  }
}

#ecycle section.hero video {
  width: 83vw;
}
#ecycle section.description button {
  background-color: var(--bs-jade-green);
  color: white;
  font-weight: 700;
  font-size: 17px;
  border: none;
  margin-top: 10.4vw;
  padding: 15px 38px;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 576px) {
  #ecycle section.description button {
    font-size: 20px;
    margin-top: 1.4vw;
    padding: 15px 38px;
  }
}
#ecycle section.description button:hover {
  transform: scale(1.05);
  filter: brightness(0.9);
}
#ecycle section.description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#ecycle section h3 {
  margin-top: 6vw;
}
#ecycle section p {
  margin: 1.5vw auto 0;
  font-size: 16px;
  width: 90vw;
  line-height: 1.55;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 576px) {
  #ecycle section p {
    font-size: 21px;
    width: 50vw;
  }
}
#ecycle section p ul {
  margin-left: 40px;
}
#ecycle section .header {
  background-color: var(--bs-jade-green);
  height: 95px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  #ecycle section .header {
    height: 150px;
    font-size: 40px;
  }
}

#ecycle section .overview-image {
  width: 46vw;
  margin-top: 11vw;
}
@media (min-width: 576px) {
  #ecycle section .overview-image {
    width: 46vw;
    margin-top: 5vw;
  }
}

#ecycle section .process-image {
  width: 53vw;
  margin-top: 1vw;
}
@media (min-width: 576px) {
  #ecycle section .process-image {
    width: 53vw;
    margin-top: 4vw;
  }
}

#ecycle section .persona-image {
  width: 84vw;
  margin-top: 7.5vw;
}
@media (min-width: 576px) {
  #ecycle section .persona-image {
    width: 60vw;
    margin-top: 2.5vw;
  }
}
#ecycle section .story-image {
  width: 82vw;
  margin-top: 6.5vw;
}
@media (min-width: 576px) {
  #ecycle section .story-image {
    width: 60vw;
    margin-top: 2.5vw;
  }
}
#ecycle section .analyze-image {
  width: 84vw;
  margin-top: 11.5vw;
}
@media (min-width: 576px) {
  #ecycle section .analyze-image {
    width: 60vw;
    margin-top: 5.5vw;
  }
}
#ecycle section .flow-image {
  width: 83vw;
  margin-top: 13.5vw;
}
@media (min-width: 576px) {
  #ecycle section .flow-image {
    width: 60vw;
    margin-top: 2.5vw;
  }
}
#ecycle section .brand-image {
  width: 83vw;
  margin-top: 6.5vw;
}
@media (min-width: 576px) {
  #ecycle section .brand-image {
    width: 60vw;
    margin-top: 1.5vw;
  }
}
#ecycle section .brand2-image {
  width: 81vw;
  margin-top: 5.5vw;
}
@media (min-width: 576px) {
  #ecycle section .brand2-image {
    width: 60vw;
    margin-top: 1.5vw;
  }
}
#ecycle section .fidelity-image {
  width: 82vw;
  margin-top: 12.5vw;
}
@media (min-width: 576px) {
  #ecycle section .fidelity-image {
    width: 60vw;
    margin-top: 1.5vw;
  }
}
