#onra section.hero {
  background-color: #414346;
  width: 100vw;
  height: 118.5vw;
  position: relative;
  z-index: -1;
}
@media (min-width: 576px) {
  #onra section.hero {
    background-color: #414346;
    height: 51.5vw;
    position: relative;
  }
}

#onra section.hero img.img1 {
  width: 75.5%;
  top: 10%;
  left: 25%;
}

#onra section.hero .overflow-area {
  position: relative;
  width: 56vw;
  height: 34.5vw;
  overflow: scroll;
  left: 22.5vw;
  top: 38vw;
}
@media (min-width: 576px) {
  #onra section.hero img.img2 {
    width: 42.5%;
    top: 20%;
    left: 29%;
  }
  #onra section.hero .overflow-area {
    width: 31vw;
    height: 19.5vw;
    overflow: scroll;
    left: 34.5vw;
    top: 16vw;
  }
}

#onra section.hero .scrollable-object {
  width: 100%;
}
#onra section.hero img.img2-overlay {
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0%;
}
#onra section.hero img.img3 {
  width: 26.5%;
  top: 29%;
  left: 68.5%;
}
#onra section.prototype {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#onra section.prototype p {
  margin: 12vw auto 0;
  font-size: 16px;
  width: 83vw;
  line-height: 1.45;
}
#onra section.prototype button {
  background-color: #414346;
  color: white;
  font-weight: 700;
  font-size: 15px;
  border: none;
  margin-top: 20.4vw;
  padding: 15px 38px;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 576px) {
  #onra section.prototype p {
    margin: 1vw auto 0;
    font-size: 21px;
    width: 50vw;
    line-height: 1.45;
  }
  #onra section.prototype button {
    font-size: 16px;
    margin-top: 1vw;
    padding: 10px 60px;
    border-radius: 8px;
  }
}
#onra section.prototype button:hover {
  transform: scale(1.05);
  filter: brightness(0.9);
}
#onra section.prototype .video-container {
  width: 88vw;
}
@media (min-width: 576px) {
  #onra section.prototype .video-container {
    width: 75vw;
    max-width: 1200px;
  }
}
#onra section.overview .header {
  background-color: var(--bs-steel-blue);
  height: 95px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  #onra section.overview .header {
    height: 150px;
    width: 100vw;
    font-size: 40px;
    font-weight: 700;
  }
}

#onra section.overview .overview-image {
  width: 68vw;
  margin-top: 13vw;
}
@media (min-width: 576px) {
  #onra section.overview .overview-image {
    width: 46vw;
    margin-top: 5vw;
  }
}

#onra section.overview h3 {
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #onra section.overview h3 {
    margin-top: 6vw;
  }
}

#onra section.overview p {
  margin-top: 6vw;
  font-size: 16px;
  width: 83vw;
  line-height: 1.55;
}
@media (min-width: 576px) {
  #onra section.overview p {
    margin-top: 2vw;
    font-size: 21px;
    width: 90vw;
    line-height: 1.45;
  }
}
@media (min-width: 900px) {
  #onra section.overview p {
    font-size: 21px;
    width: 50vw;
  }
}
#onra section.overview p ul {
  margin-left: 0px;
}
@media (min-width: 576px) {
  #onra section.overview p ul {
    margin-left: 40px;
  }
}

#onra section.overview .interview-image {
  width: 85vw;
  margin-top: 4.3vw;
}
@media (min-width: 576px) {
  #onra section.overview .interview-image {
    width: 75vw;
    margin-top: 4.3vw;
  }
}
#onra section.overview .persona-image {
  width: 85vw;
  margin-top: 15vw;
}
@media (min-width: 576px) {
  #onra section.overview .persona-image {
    width: 60vw;
    margin-top: 6.3vw;
  }
}

#onra section.overview .competitive-image {
  width: 80vw;
  margin-top: 13.3vw;
}
@media (min-width: 576px) {
  #onra section.overview .competitive-image {
    width: 60vw;
    margin-top: 4.3vw;
  }
}

#onra section.overview .userflow-image {
  width: 80vw;
  margin-top: 15.3vw;
}
@media (min-width: 576px) {
  #onra section.overview .userflow-image {
    width: 68vw;
    margin-top: 2.3vw;
  }
}
#onra section.overview .uikit-image {
  width: 90vw;
  margin-top: 5.7vw;
}
@media (min-width: 576px) {
  #onra section.overview .uikit-image {
    width: 90vw;
    margin-top: 5.7vw;
  }
}
#onra section.overview .sketch-image {
  width: 80vw;
  margin-top: 7.7vw;
}
@media (min-width: 576px) {
  #onra section.overview .sketch-image {
    width: 44vw;
    margin-top: 1.7vw;
  }
}
#onra section.overview .grid-image {
  width: 80vw;
  margin-top: 7.7vw;
  /* transform: translateX(-%); */
}
@media (min-width: 576px) {
  #onra section.overview .grid-image {
    width: 57vw;
    margin-top: 1.7vw;
    transform: translateX(-6%);
  }
}
#onra section.overview .responsive-image {
  width: 83vw;
  margin-top: 7.7vw;
}
@media (min-width: 576px) {
  #onra section.overview .responsive-image {
    width: 30vw;
    margin-top: 1.7vw;
  }
}
#onra section.overview .usertest-image {
  width: 80.5vw;
  margin-top: 5.7vw;
}
@media (min-width: 576px) {
  #onra section.overview .usertest-image {
    width: 47.5vw;
    margin-top: 5.7vw;
  }
}
#onra section.overview .testing-image {
  width: 85.5vw;
  margin-top: 5.7vw;
}
#onra section.overview .ab-testing-image {
  width: 75.5vw;
  margin-top: 15.7vw;
  /* transform: translateX(13%); */
}
@media (min-width: 576px) {
  #onra section.overview .testing-image {
    width: 74.5vw;
    margin-top: 5.7vw;
  }
  #onra section.overview .ab-testing-image {
    width: 59.5vw;
    margin-top: 5.7vw;
    transform: translateX(13%);
  }
}

#onra section.overview .high-fidelity-image {
  width: 90.5vw;
  margin-top: 2.7vw;
}
#onra .onra-title {
  position: absolute;
  color: white;
  font-size: 8.3vw;
  top: 89vw;
  left: 10vw;
}
#onra .onra-sub-title {
  position: absolute;
  color: white;
  font-size: 5vw;
  font-weight: 700;
  top: 100vw;
  left: 9.5vw;
  width: 77vw;
}
#onra .onra-sub-link {
  position: absolute;
  color: white;
}
@media (min-width: 576px) {
  #onra .onra-title {
    font-size: 3.5vw;
    top: 18vw;
    left: 18vw;
  }
  #onra .onra-sub-title {
    font-size: 1.7vw;
    top: 22vw;
    left: 16.5vw;
  }
  #onra .onra-sub-link {
    font-size: 1.5vw;
    top: 25vw;
    left: 17.5vw;
  }
}
