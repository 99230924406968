#footer {
  position: relative;
  height: 133px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
@media (min-width: 576px) {
  #footer {
    height: 293px;
  }
}
#footer:after {
  width: 100vw;
  height: 65%;
  background-color: var(--bs-main);
  content: "";
  position: absolute;
  z-index: -1;
}
@media (min-width: 992px) {
  #footer:after {
    /* height: 50%; */
  }
}
#footer img {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
#footer .navbar-list a {
  font-size: min(20px, 3vw);
  padding-bottom: 2px;
  margin-left: min(2vw, 83rem);
  margin-right: min(2vw, 83rem);
  font-family: "Roboto flex";
  font-weight: 700;
  cursor: pointer;
  color: var(--bs-white);
}
