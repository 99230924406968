#blog-post {
}
#blog-post section.row {
  padding-left: 2vw;
  padding-right: 2vw;
}
#blog-post section.row .first {
  padding-left: 4vw;
  padding-right: 4vw;
}
#blog-post section.row .first p {
  font-size: 20px;
}
#blog-post section.row .first ul,
#blog-post section.row .first ol {
}
#blog-post section.row .first img {
}
#blog-post section.row .second {
  margin-top: 0;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#blog-post .more-topics .topic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  max-width: 385px;
}
#blog-post .more-topics .topic-img {
  width: 50px;
  height: 50px;
  margin-left: 20px;
}
#blog-post .more-topics .title {
  color: var(--bs-main);
  font-size: 18px;
  font-weight: 700;
}

@media (min-width: 992px) {
  #blog-post section.row {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  #blog-post section.row .first {
    padding-left: 9vw;
  }
  #blog-post section.row .second {
    margin-top: 0rem;
    padding-right: 4vw;
    padding-left: 4vw;
  }
}
