// @tailwind base;
// @tailwind components;
// @tailwind utilities;
// @import _"~bootstrap/scss/bootstrap";
$font-family-sans-serif: "DM Sans", sans-serif !default;
$link-decoration: none;
$link-shade-percentage: 20%;
$link-hover-decoration: null;
$btn-border-width: 0px !default;
$btn-border-radius: 8px !default;
$btn-border-radius-sm: 8px !default;
$btn-border-radius-lg: 8px !default;
$input-border-radius: 8px !default;
$input-border-radius-sm: 8px !default;
$input-border-radius-lg: 8px !default;
$alert-border-radius: 8px !default;
$form-select-font-weight: 100 !default;
$form-select-indicator: url("data:image/svg+xml,<svg stroke='#C4C4C4' fill='#C4C4C4' stroke-width='0' viewBox='0 0 12 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M0 5l6 6 6-6H0z'></path></svg>") !default;
$input-disabled-bg: #e5e5e5 !default;
$modal-content-border-radius: 26px !default;
$modal-content-border-width: none !default;
$modal-content-bg: var(--bs-quaternary) !default;
$form-select-border-width: 0px !default;
$modal-backdrop-opacity: 1 !default;
$enable-smooth-scroll: false !default;
// $input-font-size-sm:  !default;
$input-font-size-lg: 18px !default;
$input-padding-x-lg: 0.5rem !default;
$input-padding-y-lg: 0.66rem !default;
// $input-btn-border-width: 0px !default;
$primary: #00213b !default;
$secondary: #004479 !default;
$tertiary: #40608a !default;
$quaternary: #dfebf1 !default;
$danger: #ff435f !default;
$darkblack: #3a3f43 !default;
$lightgray: #7e8285 !default;

@import "./functions";
@import "./variables";
@import "./maps";
@import "./mixins";
@import "./utilities";

$headings-color: $primary !default;
$link-hover-color: $secondary;
$btn-link-hover-color: $secondary !default;
$btn-link-disabled-color: #c4c4c4 !default;

// Layout & components
@import "./root";
@import "./reboot";
@import "./type";
@import "./images";
@import "./containers";
@import "./grid";
@import "./tables";
@import "./forms";
@import "./buttons";
@import "./transitions";
@import "./dropdown";
@import "./button-group";
@import "./nav";
@import "./navbar";
@import "./card";
@import "./accordion";
@import "./breadcrumb";
@import "./pagination";
@import "./badge";
@import "./alert";
@import "./progress";
@import "./list-group";
@import "./close";
@import "./toasts";
@import "./modal";
@import "./tooltip";
@import "./popover";
@import "./carousel";
@import "./spinners";
@import "./offcanvas";
@import "./placeholders";
@import "./_customized.scss";

// Helpers
@import "./helpers";

// Utilities
@import "./utilities/api";
// scss-docs-end import-stack

.form-check-input[type="radio"] {
  background-size: 8px;
}
.form-check-input[type="checkbox"] {
  // border-width: 1px;
}
.form-check-input:checked[type="checkbox"] {
  // background-color: $white;
  border-color: var(--bs-primary);
}
.form-control {
  border: none;
}
.form-label {
  margin-bottom: 5px;
}
.form-control:focus,
.form-select:focus,
button:focus:not(:focus-visible),
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: none;
  filter: drop-shadow(0px 0px 5px rgba(0, 68, 121, 0.5));
}
h1 {
  @extend %heading;
  font-size: 50px;
}

h2 {
  @extend %heading;
  font-size: 44px;
}

h3 {
  @extend %heading;
  font-size: 24px;
}
.display1 {
  @extend %heading;
  font-size: 80px;
}
.display2 {
  @extend %heading;
  font-size: 70px;
}
.display3 {
  @extend %heading;
  font-size: 60px;
}
.form-heading {
  @extend %heading;
  font-size: 16px;
}
.card-display {
  @extend %heading;
  font-size: 24px;
}
.subheading {
  @extend %heading;
  font-size: 22px;
}
.body1 {
  @extend p;
  font-size: 18px;
}
.body2 {
  @extend p;
  font-size: 16px;
}
.form-select-lg {
  min-height: calc(1.5em + 1rem + 2px);
}
.modal-backdrop {
  background-color: #000000d6;
  backdrop-filter: blur(6px);
}
.form-overlay {
  position: absolute;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
}
.form-overlay-full {
  border-radius: 26px;
}
.form-overlay-half {
  border-radius: 0px 0px 26px 26px;
}
.fill-available {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.form-select:disabled,
.form-control:disabled,
.form-control[readonly] {
  border: 1px solid var(--bs-dark4);
  color: var(--bs-dark1);
}
.form-select:disabled {
  background-color: var(--bs-light);
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: var(--bs-dark4);
}
.form-label {
  font-size: 16px;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27black%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
  width: 24px;
  height: 24px;
}
.form-check-input[type="checkbox"] {
  width: 24px;
  height: 24px;
  // margin-right: 10px;
  margin-top: 1px;
  background-color: transparent;
}
.form-check-label {
  padding-left: 10px;
}
.btn-primary-hover:hover {
  background-color: var(--bs-secondary);
}
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: unset;
}
.webkit-appearance-none {
  -webkit-appearance: unset !important;
}
select,
input {
  -webkit-appearance: unset !important;
}

// Dashboard
.dh1 {
  @extend %heading;
  font-size: 36px;
}

.dh2 {
  @extend %heading;
  font-size: 24px;
}

.dh3 {
  @extend %heading;
  font-size: 20px;
}
.dform-heading {
  @extend %heading;
  font-size: 16px;
}

.dsubheading {
  @extend %heading;
  font-size: 18px;
}
.dbody1 {
  @extend p;
  font-size: 18px;
}
.dbody2 {
  @extend p;
  font-size: 16px;
}
.dbody3 {
  @extend p;
  font-size: 14px;
}
.dashboard-btn-primary-hover:hover {
  color: var(--bs-white) !important;
  background-color: var(--bs-blue500) !important;
}
.dashboard-btn:disabled,
.dashboard-btn.disabled {
  background-color: var(--bs-blue100);
  color: var(--bs-neutral300);
}
.dashboard-btn:active,
.dashboard-btn.active,
.dashboard-btn:focus {
  color: var(--bs-white) !important;
  background-color: var(--bs-blue500) !important;
}
input[type="radio"].dashboard-radio:checked,
.dashboard-radio > input[type="radio"]:checked {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 7px solid #004479;
}
input[type="radio"].dashboard-radio,
.dashboard-radio > input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
}

input[type="radio"].dashboard-radio-blue:checked,
.dashboard-radio-blue > input[type="radio"]:checked {
  border: 7px solid var(--bs-blue300) !important;
  background-color: var(--bs-white) !important;
}
input[type="radio"].dashboard-radio-blue,
.dashboard-radio-blue > input[type="radio"] {
  border: 7px solid var(--bs-neutral200) !important;
  background-color: #579aff !important;
}
input[type="radio"].dashboard-radio-purple:checked,
.dashboard-radio-purple > input[type="radio"]:checked {
  border: 7px solid #e786d7 !important;
  background-color: var(--bs-white) !important;
}
input[type="radio"].dashboard-radio-purple,
.dashboard-radio-purple > input[type="radio"] {
  border: 7px solid var(--bs-neutral200) !important;
  background-color: #e786d7 !important;
}
.dashboard-input:focus {
  // filter: none;
  box-shadow: none;
  border: 1px solid var(--bs-blue300);
}
.dashboard-select {
  background-image: url("data:image/svg+xml,%3csvg stroke=%27%23C4C4C4%27 fill=%27%23000000%27 stroke-width=%270%27 viewBox=%270 0 12 16%27 height=%271em%27 width=%271em%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M0 5l6 6 6-6H0z%27%3e%3c/path%3e%3c/svg%3e");
}
.modal-dialog {
  margin: auto;
}
@media (min-width: 992px) {
  .modal-mdlg {
    max-width: 550px;
  }
  .modal-mdhlg {
    max-width: 620px;
  }
}
.br8 {
  border-radius: 8px;
}
.br10 {
  border-radius: 10px;
}
.br16 {
  border-radius: 16px;
}
.slick-slide {
  outline: none;
}
.nav-link:hover,
.nav-link:focus {
  outline: none;
}
