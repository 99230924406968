#about {
}

#about .avatar-wobble {
  width: 80vw;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morphing 12s ease-in-out alternate infinite;
  object-fit: cover;
}
@media (min-width: 576px) {
  #about .avatar-wobble {
    width: 29vw;
  }
}
@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    /* box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2); */
  }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    /* box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2); */
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}
#about section.row {
  padding-left: 2vw;
  padding-right: 2vw;
}
#about section.row .first {
  padding-left: 4vw;
  padding-right: 4vw;
}
#about section.row .second {
  margin-top: 4rem;
  padding-right: 4vw;
  padding-left: 4vw;
  padding-bottom: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 576px) {
  #about section.row .second {
    margin-top: 4rem;
    padding-right: 4vw;
    padding-left: 4vw;
    padding-bottom: 4vw;
  }
}

#about section.row .content {
  font-size: 16px;
  padding: 0px 6vw;
}
@media (min-width: 576px) {
  #about section.row .content {
    font-size: 20px;
    padding: 0;
  }
}

@media (min-width: 992px) {
  #about section.row {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  #about section.row .first {
    padding-left: 9vw;
  }
  #about section.row .second {
    margin-top: 0rem;
    padding-right: 9vw;
    padding-left: 9vw;
  }
}
#about table .bl-black {
  border-left: 1px solid black;
}
#about table .bt-black {
  border-top: 1px solid black;
}
