#footer2 {
}
#footer2 {
  position: relative;
  height: 100px;
  width: 100vw;
  /* background: var(--bs-main); */
  bottom: 0;
}
@media (min-width: 576px) {
  #footer2 {
    position: relative;
    height: 378px;
    width: 100vw;
    /* background: var(--bs-main); */
    bottom: 0;
  }
}
#footer2 .footer-container {
  position: absolute;
  width: 100vw;
  bottom: 0;
  height: 346px;
  /* border: 3px dashed red; */
  margin-left: 0;
}

#footer2 .footer-bg {
  position: absolute;
  z-index: -1;
  width: 100vw;
}
#footer2 .logo-section {
  margin-left: 0;
}
#footer2 .footer-logo {
}
#footer2 button,
#footer2 .button {
  border-radius: 0;
  font-size: 20px;
  font-weight: 700;
  background-color: white;
  color: var(--bs-main);
  /* margin-left: 14px; */
}
#footer2 .logo-section button,
#footer2 .logo-section .button {
  width: 120px;
  margin-right: 20px;
}
#footer2 .navigator-section button,
#footer2 .navigator-section .button {
  width: 220px;
  margin-right: 20px;
  margin-bottom: 20px;
}
#footer2 .navigator-section {
  width: 470px;
  margin-right: 0vw;
}

@media (min-width: 768px) {
  #footer2 .logo-section {
    margin-left: 13vw;
  }
  #footer2 .footer-container {
    height: 266px;
  }
  #footer2 .navigator-section {
    width: 470px;
    margin-right: 11vw;
  }
}
