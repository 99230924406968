html {
  overflow-x: hidden;
}
body {
  width: 100%;
}
#homepage {
  /* background-color: #f2f7fb; */
}
#homepage .hero {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 0px;
}
@media (min-width: 576px) {
  #homepage .hero {
    width: 74vw;
    justify-content: space-between;
    margin-top: 73px;
  }
}
#homepage .introduction {
}
#homepage .paper-plane {
  width: 85%;
  margin-left: 10vw;
  margin-top: 20px;
}
@media (min-width: 576px) {
  #homepage .paper-plane {
    width: 100%;
    margin-left: -4vw;
    margin-top: 55px;
  }
}
#homepage .paper-plane svg {
  width: 100%;
}
#homepage .profile-section {
  margin-right: 0vw;
  margin-top: 40px;
  display: flex;
}
@media (min-width: 576px) {
  #homepage .profile-section {
    margin-right: 0vw;
    margin-top: 80px;
    display: flex;
  }
}
#homepage .avatar-wobble {
  width: 80vw;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morphing 12s ease-in-out alternate infinite;
  object-fit: cover;
}
@media (min-width: 576px) {
  #homepage .avatar-wobble {
    width: 29vw;
  }
}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    /* box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2); */
  }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    /* box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2); */
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}
#homepage #section-youtube {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  #homepage #section-youtube {
    width: 75%;
    max-width: 1080px;
  }
}
#homepage #section-youtube .title {
  color: var(--bs-main);
  font-size: 23px;
  font-weight: 700;
}
@media (min-width: 576px) {
  #homepage #section-youtube .title {
    font-size: 2.5vw;
  }
}
#homepage #section-youtube .card {
  display: block;
  width: 100%;
  margin-top: 0.7vw;
  border: none;
}
/* #homepage .crafting-video {
  width: fit-content;
}
@media (min-width: 576px) {
  #homepage .crafting-video {
    width: fit-content;
    min-width: 36vw;
  }
}
#homepage .crafting-title {
  font-size: 40px;
}
@media (min-width: 576px) {
  #homepage .crafting-title {
    font-size: 49px;
  }
}
#homepage .crafting-subtitle {
  font-size: 17;
  margin-top: 1rem;
}
@media (min-width: 576px) {
  #homepage .crafting-subtitle {
    margin-top: 0rem;
    font-size: 20px;
    width: 75%;
  }
} */

#homepage .introduction .hi-text {
  font-size: 32px;
  text-align: center;
  margin-top: 10px;
  color: #3a3f43;
}
@media (min-width: 576px) {
  #homepage .introduction .hi-text {
    margin-top: 0px;
    text-align: left;
    font-size: 4.4vw;
    line-height: 1.3;
  }
}
#homepage .introduction .sub-text {
  font-size: 1.7vw;
  color: #3a3f43;
}
#homepage .introduction .sub-text-role {
  font-size: 1.65vw;
  color: #3a3f43;
  font-weight: 700;
  margin-top: 1vw;
}

#homepage .introduction .sub-text-location {
  font-size: 1.35vw;
  margin-top: 0.7vw;
  font-style: italic;
  color: #3a3f43;
}

#homepage .background-photo {
  width: 100vw;
  z-index: -1;
  position: absolute;
  top: 0;
}
#homepage #sec-cards-wrapper {
  background-color: #f2f7fb;
}
#homepage #sec-cards {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  #homepage #sec-cards {
    width: 75%;
    max-width: 1080px;
  }
}

#homepage #sec-cards .card {
  width: 100%;
  max-width: 528px;
  border-radius: 24px;
  margin-bottom: 25px;
}
@media (min-width: 576px) {
  #homepage #sec-cards .card {
    width: 36.66vw;
    max-width: 528px;
    border-radius: 24px;
    margin-bottom: 25px;
  }
  #homepage #sec-cards .card:nth-child(2n) {
    margin-left: auto;
  }
}
#homepage #sec-cards .card .img-container {
  background-color: var(--bs-main);
  border-radius: 24px 24px 0 0;
  padding: 12px;
  width: 100%;
  height: 46.11vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 576px) {
  #homepage #sec-cards .card .img-container {
    padding: 24px 30px;
    height: 26.11vw;
  }
}

#homepage #sec-cards .card .img-container img {
  /* margin: 5%; */
  max-height: 90%;
  max-width: 98%;
  width: auto;
}
#homepage #sec-cards .card .card-body {
  padding: 4% 4%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#homepage #sec-cards .card .card-title {
  font-weight: 700;
  font-size: 32px;
  font-family: DM sans;
}
#homepage #sec-cards .card .card-text {
  font-weight: 400;
  font-size: 20px;
  font-family: DM sans;
  color: #7e8285;
  margin-bottom: 5px;
}
#homepage #sec-cards .card .card-subtext {
  font-weight: 400;
  font-size: 16px;
  font-family: DM sans;
  color: #7e8285;
}
#homepage #sec-cards .card .btn {
  font-weight: 700;
  font-size: 20px;
  font-family: DM sans;
  height: 40px;
  width: 140px;
  font-family: Inter;
  font-size: 20px;
  color: #5193c6;
  background-color: #d9eeff;
  margin-top: 5px;
}
#homepage #sec-cards .card .btn:hover {
  color: #d9eeff;
  background-color: #5193c6;
}

#homepage #sec-skills .section-pic {
  width: 100vw;
  margin-left: 0vw;
  margin-right: 0vw;
  margin-top: 15vw;
}
@media (min-width: 576px) {
  #homepage #sec-skills .section-pic {
    width: 30vw;
    margin-left: 20vw;
    margin-right: 1vw;
    margin-top: 6vw;
    margin-bottom: 3vw;
  }
}

#homepage #sec-skills .section-pic {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morphing 12s ease-in-out alternate infinite;
  object-fit: cover;
}
#homepage #sec-skills .list {
  margin-top: 14vw;
  margin-left: 3vw;
  margin-right: 3vw;
}
@media (min-width: 576px) {
  #homepage #sec-skills .list {
    margin-right: 0;
    margin-top: 3vw;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  #homepage #sec-skills .list {
    margin-right: 5.8vw;
  }
  #homepage #sec-skills .list .col {
    min-width: 320px;
  }
}
#homepage #sec-skills .list ul {
  list-style-type: none;
  padding-inline-start: 13px;
  color: #3a3f43;
}

@media (min-width: 576px) {
  #homepage #sec-skills .list ul {
    list-style-type: disc;
    padding-inline-start: 40px;
  }
}

#homepage #sec-skills .list span {
  margin-left: 13px;
}
#homepage #sec-skills .list li {
  font-size: 16px;
}
#homepage #sec-skills .list ul.problem-solving {
  text-align: right;
}
@media (min-width: 576px) {
  #homepage #sec-skills .list ul.problem-solving {
    text-align: left;
  }
}
#homepage #sec-skills .list .col {
  min-width: 280px;
}
#homepage .social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--bs-main);
  border-radius: 50%;
  margin-top: 30px;
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}
#homepage .social-icon:hover {
  transform: scale(1.08);
  background-color: #006bbd;
}
#homepage .social-icon svg {
  display: block;
  width: 25px;
  height: 25px;
  color: white;
}
#homepage .social-container1 {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: -4rem;
}
#homepage .social-container2 {
  margin-top: auto;
  margin-bottom: 4rem;
  margin-right: calc(-24vw + 2.5rem);
}
