#other section.hero {
  background-color: var(--bs-robin-egg);
  width: 100vw;
  height: 118.5vw;
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
@media (min-width: 576px) {
  #other section.hero {
    height: 65.5vw;
  }
}
#other section.hero img {
  width: 84vw;
  padding-top: 2vw;
  margin-left: 8vw;
}
@media (min-width: 576px) {
  #other section.hero img {
    width: 54vw;
    padding-top: 4vw;
    margin-left: -2vw;
  }
}
#other section.hero h1 {
  position: absolute;
  color: white;
  font-weight: 700;
  font-size: 8.3vw;
  top: 89vw;
  left: 10vw;
}
@media (min-width: 576px) {
  #other section.hero h1 {
    position: initial;
    font-size: 3.3vw;
    margin-left: -4vw;
    margin-top: -9vw;
  }
}
#other section.hero h4 {
  position: absolute;
  color: white;
  font-weight: 700;
  font-size: 5.3vw;
  top: 100vw;
  left: 20vw;
}
@media (min-width: 576px) {
  #other section.hero h4 {
    position: initial;
    font-size: 1.3vw;
    margin-left: -18vw;
    margin-top: -2vw;
  }
}

#other section button.button {
  background-color: var(--bs-robin-egg);
  color: white;
  font-weight: 700;
  font-size: 20px;
  border: none;
  margin-top: 1.4vw;
  padding: 15px 38px;
}
@media (min-width: 576px) {
  #other section button.button {
    font-size: 20px;
    margin-top: 1.4vw;
    padding: 15px 38px;
  }
}

#other section.description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#other section h3 {
  margin-top: 6vw;
}
#other section p {
  margin-top: 2vw;
  font-size: 16px;
  width: 90vw;
  line-height: 1.45;
  margin-left: auto;
  margin-right: auto;
}
#other section p ul {
  margin-left: 40px;
}
@media (min-width: 576px) {
  #other section p {
    font-size: 21px;
    width: 50vw;
  }
}
#other section .header {
  background-color: #dd9797;
  height: 95px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  #other section .header {
    height: 150px;
    font-size: 40px;
  }
}

#other section .illustrations-icon-image {
  width: 33vw;
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #other section .illustrations-icon-image {
    width: 9vw;
    margin-top: 5vw;
  }
}

#other section .illustrations-image {
  width: 82vw;
  margin-top: 13vw;
}
@media (min-width: 576px) {
  #other section .illustrations-image {
    width: 90vw;
    margin-top: 6vw;
  }
}

#other section .modeling-image {
  width: 82vw;
}
@media (min-width: 576px) {
  #other section .modeling-image {
    width: 59vw;
  }
}
#other section .painting-icon-image {
  width: 48vw;
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #other section .painting-icon-image {
    width: 13vw;
    margin-top: 5vw;
  }
}
#other section .painting-image {
  width: 82vw;
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #other section .painting-image {
    width: 29vw;
    margin-top: 5vw;
  }
}

#other section .character-icon-image {
  width: 48vw;
  margin-top: 13vw;
}
@media (min-width: 576px) {
  #other section .character-icon-image {
    width: 13vw;
    margin-top: 5vw;
  }
}

#other section .character-image {
  width: 83vw;
  margin-top: 13vw;
}
@media (min-width: 576px) {
  #other section .character-image {
    width: 60vw;
    margin-top: 5vw;
  }
}

#other section .logo-icon-image {
  width: 33vw;
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #other section .logo-icon-image {
    width: 9vw;
    margin-top: 5vw;
  }
}

#other section .logo-image {
  width: 82vw;
  margin-top: 2vw;
}
@media (min-width: 576px) {
  #other section .logo-image {
    width: 45vw;
    margin-top: 0vw;
  }
}

#other section .logo2-image {
  width: 82vw;
  margin-top: 2vw;
}
@media (min-width: 576px) {
  #other section .logo2-image {
    width: 29vw;
    margin-top: 0vw;
  }
}
#other section .logo3-image {
  width: 82vw;
  margin-top: -9vw;
}
@media (min-width: 576px) {
  #other section .logo3-image {
    width: 44vw;
    margin-top: 0vw;
  }
}

#other section .motion-icon-image {
  width: 59vw;
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #other section .motion-icon-image {
    width: 16vw;
    margin-top: 5vw;
  }
}
#other section .motion-video {
  width: 82vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #other section .motion-video {
    width: 75vw;
    margin-top: 0vw;
  }
}
#other section .farm-image {
  width: 82vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #other section .farm-image {
    width: 74vw;
    margin-top: 0vw;
  }
}
#other section .artworks {
  width: 82vw;
  margin: 0 auto;
}
@media (min-width: 576px) {
  #other section .artworks {
    width: 74vw;
  }
}
#other section .artworks > div {
  padding: 0 1%;
}
@media (min-width: 576px) {
  #other section .artworks > div {
    padding: 0 3%;
  }
}
#other section .artworks > div img {
  width: 100%;
}
@media (min-width: 576px) {
  #other section .artworks > div img {
  }
}
#other .slider-container {
  width: 90vw;
  margin: 0 auto;
  z-index: 0;
}
@media (min-width: 576px) {
  #other .slider-container {
    width: 50vw;
  }
}
#other .slick-prev {
  left: 5%;
  z-index: 1;
}
#other .slick-next {
  right: 5%;
  z-index: 1;
}
