#sib_bakery section.hero {
  background-color: var(--bs-coral);
  width: 100vw;
  height: 118.5vw;
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
@media (min-width: 576px) {
  #sib_bakery section.hero {
    background-color: var(--bs-coral);
    height: 51.5vw;
  }
}

#sib_bakery section.hero img {
  width: 100vw;
  margin-top: -8vw;
}
@media (min-width: 576px) {
  #sib_bakery section.hero img {
    margin-top: 2vw;
    width: 62vw;
    margin-left: -36vw;
  }
}

#sib_bakery section.description video {
  height: 28vw;
}
#sib_bakery section.description a {
  width: fit-content;
}
#sib_bakery section.description button {
  background-color: #dd9797;
  color: white;
  font-weight: 700;
  font-size: 16px;
  border: none;
  margin-top: 9.4vw;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 576px) {
  #sib_bakery section.description button {
    font-size: 16px;
    margin-top: 1.4vw;
  }
}
#sib_bakery section.description button:hover {
  transform: scale(1.05);
  filter: brightness(0.9);
}
#sib_bakery section.description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#sib_bakery section h3 {
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #sib_bakery section h3 {
    margin-top: 6vw;
  }
}
#sib_bakery section p {
  margin-top: 2vw;
  font-size: 16px;
  width: 90vw;
  line-height: 1.45;
  margin-left: auto;
  margin-right: auto;
}
#sib_bakery section p ul {
  margin-left: 0px;
}
@media (min-width: 576px) {
  #sib_bakery section p {
    font-size: 18px;
    width: 47vw;
  }
  #sib_bakery section p ul {
    margin-left: 40px;
  }
}
#sib_bakery section .header {
  background-color: #dd9797;
  height: 95px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  #sib_bakery section .header {
    height: 150px;
    font-size: 40px;
  }
}

#sib_bakery .sib-title {
  position: absolute;
  color: #3a3f43;
  font-size: 8.3vw;
  top: 89vw;
  left: 10vw;
  font-weight: 700;
}
#sib_bakery .sib-sub-title {
  position: absolute;
  color: #3a3f43;
  font-size: 5vw;
  top: 100vw;
  left: 9.5vw;
  width: 64.5vw;
}
@media (min-width: 576px) {
  #sib_bakery .sib-title {
    position: absolute;
    font-size: 3.3vw;
    font-weight: 600;
    top: 21.5vw;
    left: 68vw;
  }
  #sib_bakery .sib-sub-title {
    position: absolute;
    font-size: 1.6vw;
    top: 26vw;
    left: 65vw;
  }
}
#sib_bakery .process-table {
  width: 100%;
}
#sib_bakery .overview-image {
  width: 80vw;
  margin-top: 5vw;
}
@media (min-width: 576px) {
  #sib_bakery .overview-image {
    width: 46vw;
    margin-top: 5vw;
  }
}
#sib_bakery .process-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .process-image {
    width: 46vw;
    margin-top: 5vw;
  }
}
#sib_bakery .affinity-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .affinity-image {
    width: 60vw;
    margin-top: 0vw;
  }
}
#sib_bakery .empathy-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .empathy-image {
    width: 60vw;
    margin-top: 0vw;
  }
}
#sib_bakery .competitor-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .competitor-image {
    width: 76vw;
    margin-top: 0vw;
  }
}
#sib_bakery .persona-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .persona-image {
    width: 60vw;
    margin-top: 0vw;
  }
}
#sib_bakery .userflow-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .userflow-image {
    width: 75vw;
    margin-top: 0vw;
  }
}
#sib_bakery .taskflow-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .taskflow-image {
    width: 60vw;
    margin-top: 0vw;
  }
}
#sib_bakery .sitemap-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .sitemap-image {
    width: 62vw;
    margin-top: 0vw;
  }
}
#sib_bakery .midfidelity-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .midfidelity-image {
    width: 45vw;
    margin-top: 0vw;
  }
}
#sib_bakery .version-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .version-image {
    width: 60vw;
    margin-top: 0vw;
  }
}
#sib_bakery .mood-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .mood-image {
    width: 62vw;
    margin-top: 0vw;
  }
}
#sib_bakery .uikit-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .uikit-image {
    width: 76vw;
    margin-top: 0vw;
  }
}
#sib_bakery .highfidelity-container {
  background-color: #dd9797;
  width: 100vw;
}
#sib_bakery .highfidelity-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .highfidelity-image {
    width: 98vw;
    margin-top: 0vw;
  }
}
#sib_bakery .usability-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .usability-image {
    width: 60vw;
    margin-top: 0vw;
  }
}
#sib_bakery .iteration1-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .iteration1-image {
    width: 75vw;
    margin-top: 0vw;
  }
}
#sib_bakery .iteration2-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .iteration2-image {
    width: 75vw;
    margin-top: 0vw;
  }
}
#sib_bakery .iteration3-image {
  width: 80vw;
  margin-top: 0vw;
}
@media (min-width: 576px) {
  #sib_bakery .iteration3-image {
    width: 75vw;
    margin-top: 0vw;
  }
}
