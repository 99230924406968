#contact {
  margin-left: 5vw;
  margin-right: 5vw;
}
@media (min-width: 992px) {
  #contact {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}
#contact .contact-details {
  font-size: 20px;
}
@media (min-width: 576px) {
  #contact .contact-details {
    font-size: 26px;
  }
}

#contact .btn-main {
  text-decoration: none;
  width: 100%;
  font-size: 20px;
  border: none;
  padding: 14px 22px;
}
@media (min-width: 576px) {
  #contact .btn-main {
    padding: 7px 22px;
    font-size: 25px;
    width: fit-content;
  }
}
#contact input,
#contact textarea {
  border-radius: 0;
}
#contact label {
  font-size: 20px;
  font-weight: 500;
}
