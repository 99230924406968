#ftd section.hero {
  background-color: var(--bs-dust-storm);
  width: 100vw;
  height: 118.5vw;
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
@media (min-width: 576px) {
  #ftd section.hero {
    background-color: #adccd9;
    height: 65.5vw;
  }
}

#ftd section.hero img {
  width: 100vw;
  margin-top: -8vw;
}
@media (min-width: 576px) {
  #ftd section.hero img {
    margin-top: 0vw;
    width: 100vw;
  }
}

#ftd section.description button {
  background-color: var(--bs-dust-storm);
  color: #1b1818;
  font-weight: 700;
  font-size: 16px;
  border: none;
  margin-top: 9.4vw;
  padding: 15px 38px;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 576px) {
  #ftd section.description button {
    font-size: 20px;
    margin-top: 1.4vw;
    padding: 15px 38px;
  }
}
#ftd section.description button:hover {
  transform: scale(1.05);
  filter: brightness(0.9);
}
#ftd section.description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#ftd section h3 {
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #ftd section h3 {
    margin-top: 6vw;
  }
}
#ftd section p {
  margin-top: 2vw;
  font-size: 16px;
  width: 90vw;
  line-height: 1.45;
  margin-left: auto;
  margin-right: auto;
}
#ftd section p ul {
  margin-left: 0px;
}
@media (min-width: 576px) {
  #ftd section p {
    font-size: 21px;
    width: 50vw;
  }
  #ftd section p ul {
    margin-left: 40px;
  }
}
#ftd section .header {
  background-color: var(--bs-dust-storm);
  height: 95px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: #1b1818;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  #ftd section .header {
    height: 150px;
    font-size: 40px;
  }
}

#ftd section .discovery-list {
  width: 32vw;
  min-width: 400px;
}

#ftd section .discovery-list ul {
  list-style: none;
}
#ftd section .discovery-list li {
  text-align: center;
}
#ftd section .discover-image {
  width: 79%;
}
@media (min-width: 576px) {
  #ftd section .discover-image {
    width: 100%;
  }
}
#ftd section .sticky-image {
  width: 83vw;
  margin-top: 12.5vw;
}
@media (min-width: 576px) {
  #ftd section .sticky-image {
    width: 47vw;
    margin-top: 7.5vw;
  }
}
#ftd section .error-image {
  width: 82vw;
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #ftd section .error-image {
    width: 47vw;
    margin-top: 3vw;
  }
}
#ftd section .brand-image {
  width: 83vw;
  margin-top: 5vw;
}
@media (min-width: 576px) {
  #ftd section .brand-image {
    width: 60vw;
    margin-top: 1vw;
  }
}
#ftd section .wcag-image {
  width: 82vw;
  margin-top: 7vw;
}
@media (min-width: 576px) {
  #ftd section .wcag-image {
    width: 75vw;
    margin-top: 1vw;
  }
}
#ftd section .fidelity-image {
  width: 82vw;
  margin-top: 2vw;
}
@media (min-width: 576px) {
  #ftd section .fidelity-image {
    width: 60vw;
    margin-top: 2vw;
  }
}
#ftd section .fidelity-video {
  width: 88vw;
  margin-top: 18vw;
}
@media (min-width: 576px) {
  #ftd section .fidelity-video {
    width: 58vw;
    margin-top: 9vw;
  }
}
#ftd .ftd-title {
  position: absolute;
  color: black;
  font-size: 8.3vw;
  top: 89vw;
  left: 10vw;
  font-weight: 700;
}
#ftd .ftd-sub-title {
  position: absolute;
  color: black;
  font-size: 5vw;
  top: 100vw;
  left: 9.5vw;
  width: 77vw;
}
@media (min-width: 576px) {
  #ftd .ftd-title {
    position: absolute;
    color: white;
    font-size: 3.3vw;
    font-weight: 500;
    top: 49vw;
    left: 47vw;
  }
  #ftd .ftd-sub-title {
    position: absolute;
    color: white;
    font-size: 1.6vw;
    top: 54vw;
    left: 39vw;
  }
}
