#header2 {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
@media (min-width: 576px) {
  #header2 {
    position: fixed;
    width: 100vw;
    top: unset;
    left: unset;
    background-color: #ffffff;
  }
}
#header-height2.header-height2 {
  height: 104px;
}
#header2 .logo {
  width: 50px;
  margin-left: 30px;
  cursor: pointer;
  display: none;
}
@media (min-width: 576px) {
  #header2 .logo {
    width: 90px;
    margin-left: min(12.4vw, 180px);
    display: block;
  }
}

#header2 .navbar-list {
  margin-right: min(158px, 10vw);
  margin-top: 6px;
  align-items: center;
}
#header2 .navbar-list a {
  font-size: min(20px, 3vw);
  padding-bottom: 2px;
  margin-left: min(2vw, 83rem);
  margin-right: min(2vw, 83rem);
  font-family: "Roboto flex";
  font-weight: 300;
  cursor: pointer;
  color: var(--bs-main);
}

#header2 .navbar-list a.active {
  font-weight: 700;
  border-bottom: 3px var(--bs-main) solid;
}
#header2 .navbar-list a:hover {
  font-weight: 700;
}
#header2 .menu-toggle {
  margin-right: 30px;
}
.offcanvas-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.offcanvas {
  background-color: white !important;
  max-width: 345px;
}
.offcanvas-header {
  padding: 39px 39px !important;
}
#menu-offcanvas .logo {
  width: 63px;
}
.offcanvas-body {
  padding: 39px 39px !important;
}
.offcanvas-body .navbar-list {
  display: flex;
  flex-direction: column;
}
.offcanvas-body .navbar-list a {
  font-size: 20px;
  margin-bottom: 18px;
  font-weight: 300;
  cursor: pointer;
  color: var(--bs-main);
  width: fit-content;
}

.offcanvas-body .navbar-list a.active {
  font-weight: 700;
  border-bottom: 3px var(--bs-main) solid;
}
.offcanvas-body .navbar-list a:hover {
  font-weight: 700;
}
