#plando section.hero {
  background-color: var(--bs-steel-blue);
  width: 100vw;
  height: 118.5vw;
  position: relative;
  z-index: -1;
}
@media (min-width: 576px) {
  #plando section.hero {
    background-color: var(--bs-steel-blue);
    height: 65.5vw;
    position: relative;
  }
}
#plando section.hero img {
  position: absolute;
}
#plando section.hero img.img1 {
  width: 28.5%;
  top: 29%;
  left: 5%;
}
#plando section.hero img.img2 {
  width: 75.5%;
  top: 28%;
  left: 13%;
}
#plando section.hero .overflow-area {
  position: relative;
  width: 56vw;
  height: 34.5vw;
  overflow: scroll;
  left: 22.5vw;
  top: 38vw;
}
@media (min-width: 576px) {
  #plando section.hero img.img2 {
    width: 42.5%;
    top: 20%;
    left: 29%;
  }
  #plando section.hero .overflow-area {
    width: 31vw;
    height: 19.5vw;
    overflow: scroll;
    left: 34.5vw;
    top: 16vw;
  }
}

#plando section.hero .scrollable-object {
  width: 100%;
}
#plando section.hero img.img2-overlay {
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0%;
}
#plando section.hero img.img3 {
  width: 26.5%;
  top: 29%;
  left: 68.5%;
}
#plando section.prototype {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#plando section.prototype p {
  margin: 12vw auto 0;
  font-size: 16px;
  width: 83vw;
  line-height: 1.45;
}
#plando section.prototype button {
  background-color: var(--bs-steel-blue);
  color: white;
  font-weight: 700;
  font-size: 15px;
  border: none;
  margin-top: 20.4vw;
  padding: 15px 38px;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 576px) {
  #plando section.prototype p {
    margin: 6vw auto 0;
    font-size: 21px;
    width: 50vw;
    line-height: 1.45;
  }
  #plando section.prototype button {
    font-size: 20px;
    margin-top: 1.4vw;
    padding: 15px 38px;
  }
}
#plando section.prototype button:hover {
  transform: scale(1.05);
  filter: brightness(0.9);
}
#plando section.prototype .video-container {
  width: 88vw;
}
@media (min-width: 576px) {
  #plando section.prototype .video-container {
    width: 75vw;
    max-width: 1200px;
  }
}
#plando section.overview .header {
  background-color: var(--bs-steel-blue);
  height: 95px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  #plando section.overview .header {
    height: 150px;
    width: 100vw;
    font-size: 40px;
    font-weight: 700;
  }
}

#plando section.overview .overview-image {
  width: 68vw;
  margin-top: 13vw;
}
@media (min-width: 576px) {
  #plando section.overview .overview-image {
    width: 46vw;
    margin-top: 5vw;
  }
}

#plando section.overview h3 {
  margin-top: 12vw;
}
@media (min-width: 576px) {
  #plando section.overview h3 {
    margin-top: 6vw;
  }
}

#plando section.overview p {
  margin-top: 6vw;
  font-size: 16px;
  width: 83vw;
  line-height: 1.55;
}
@media (min-width: 576px) {
  #plando section.overview p {
    margin-top: 2vw;
    font-size: 21px;
    width: 90vw;
    line-height: 1.45;
  }
}
@media (min-width: 900px) {
  #plando section.overview p {
    font-size: 21px;
    width: 50vw;
  }
}
#plando section.overview p ul {
  margin-left: 0px;
}
@media (min-width: 576px) {
  #plando section.overview p ul {
    margin-left: 40px;
  }
}

#plando section.overview .interview-image {
  width: 85vw;
  margin-top: 4.3vw;
}
@media (min-width: 576px) {
  #plando section.overview .interview-image {
    width: 75vw;
    margin-top: 4.3vw;
  }
}
#plando section.overview .persona-image {
  width: 85vw;
  margin-top: 15vw;
}
@media (min-width: 576px) {
  #plando section.overview .persona-image {
    width: 60vw;
    margin-top: 6.3vw;
  }
}

#plando section.overview .competitive-image {
  width: 80vw;
  margin-top: 13.3vw;
}
@media (min-width: 576px) {
  #plando section.overview .competitive-image {
    width: 60vw;
    margin-top: 4.3vw;
  }
}

#plando section.overview .userflow-image {
  width: 80vw;
  margin-top: 15.3vw;
}
@media (min-width: 576px) {
  #plando section.overview .userflow-image {
    width: 68vw;
    margin-top: 2.3vw;
  }
}
#plando section.overview .uikit-image {
  width: 90vw;
  margin-top: 5.7vw;
}
@media (min-width: 576px) {
  #plando section.overview .uikit-image {
    width: 90vw;
    margin-top: 5.7vw;
  }
}
#plando section.overview .sketch-image {
  width: 80vw;
  margin-top: 7.7vw;
}
@media (min-width: 576px) {
  #plando section.overview .sketch-image {
    width: 44vw;
    margin-top: 1.7vw;
  }
}
#plando section.overview .grid-image {
  width: 80vw;
  margin-top: 7.7vw;
  /* transform: translateX(-%); */
}
@media (min-width: 576px) {
  #plando section.overview .grid-image {
    width: 57vw;
    margin-top: 1.7vw;
    transform: translateX(-6%);
  }
}
#plando section.overview .responsive-image {
  width: 83vw;
  margin-top: 7.7vw;
}
@media (min-width: 576px) {
  #plando section.overview .responsive-image {
    width: 30vw;
    margin-top: 1.7vw;
  }
}
#plando section.overview .usertest-image {
  width: 80.5vw;
  margin-top: 5.7vw;
}
@media (min-width: 576px) {
  #plando section.overview .usertest-image {
    width: 47.5vw;
    margin-top: 5.7vw;
  }
}
#plando section.overview .testing-image {
  width: 85.5vw;
  margin-top: 5.7vw;
}
#plando section.overview .ab-testing-image {
  width: 75.5vw;
  margin-top: 15.7vw;
  /* transform: translateX(13%); */
}
@media (min-width: 576px) {
  #plando section.overview .testing-image {
    width: 74.5vw;
    margin-top: 5.7vw;
  }
  #plando section.overview .ab-testing-image {
    width: 59.5vw;
    margin-top: 5.7vw;
    transform: translateX(13%);
  }
}

#plando section.overview .high-fidelity-image {
  width: 90.5vw;
  margin-top: 2.7vw;
}
#plando .plando-title {
  position: absolute;
  color: white;
  font-size: 8.3vw;
  top: 89vw;
  left: 10vw;
}
#plando .plando-sub-title {
  position: absolute;
  color: white;
  font-size: 5vw;
  top: 100vw;
  left: 9.5vw;
  width: 77vw;
}
@media (min-width: 576px) {
  #plando .plando-title {
    font-size: 3.3vw;
    top: 49vw;
    left: 45vw;
  }
  #plando .plando-sub-title {
    font-size: 1.6vw;
    top: 54vw;
    left: 33.5vw;
  }
}
